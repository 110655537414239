<template>
  <a-card :title="$t('拣货单详情')" style="margin: 8px">
    <a-button slot="extra" type="primary" style="margin-right: 8px" ghost v-print="'#printContent'">
      <a-icon type="printer" />{{ $t("打印") }}</a-button
    >
    <a-button
      slot="extra"
      type="primary"
      ghost
      @click="
        () => {
          this.$router.go(-1);
        }
      "
    >
      <a-icon type="left" />{{ $t("返回") }}</a-button
    >
    <section id="printContent">
      <a-spin :spinning="spinning">
        <img class="jsbarcode" id="jsbarcode" ref="jsbarcode" />
        <a-descriptions bordered>
          <a-descriptions-item label="拣货单号">
            {{ detailInfo.number }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('出库通知单号')">
            {{ detailInfo.delivery_order_number }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('仓库')">
            {{ detailInfo.warehouse_name }}
          </a-descriptions-item>
          <!-- <a-descriptions-item :label="$t('库区')">
            {{ detailInfo.area_name }}
          </a-descriptions-item> -->
          <a-descriptions-item :label="$t('创建人')">
            {{ detailInfo.creator_name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('创建时间')">
            {{ detailInfo.create_time }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('备注')">
            {{ detailInfo.remark }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('总重量(吨)')">
            {{ totalWeight }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('总体积(立方)')">
            {{ totalVolume }}
          </a-descriptions-item>
        </a-descriptions>
      </a-spin>

      <div style="margin-top: 16px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="dataItems"
          :loading="materialLoading"
          :pagination="false"
        >
        </a-table>
      </div>
    </section>
  </a-card>
</template>

<script>
import JsBarcode from "jsbarcode";
import { pickingDetail, pickingMaterials } from "@/api/stockOut";
import NP from "number-precision";

export default {
  name: "TaskDetail",
  components: {},
  data() {
    return {
      description: this.$t("详情"),
      spinning: false,
      materialLoading: false,
      detailInfo: {},
      materialItems: [],
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: this.$t("货品编码"),
          dataIndex: "material_number",
          key: "material_number",
        },
        {
          title: this.$t("货品名称"),
          dataIndex: "material_name",
          key: "material_name",
        },
        {
          title: this.$t("应拣数量"),
          dataIndex: "total_quantity",
          key: "total_quantity",
        },
        {
          title: this.$t("单位"),
          dataIndex: "unit",
          key: "unit",
        },
        {
          title: this.$t("批次编号"),
          dataIndex: "batch_number",
          key: "batch_number",
        },
        {
          title: this.$t("推荐库位"),
          dataIndex: "recommend_location",
          key: "recommend_location",
        },
      ],
    };
  },
  computed: {
    dataItems() {
      const materialItems = [];
      for (let materialItem of this.materialItems) {
        if (materialItem.recommend_location_items.length > 0) {
          for (let recommendItem of materialItem.recommend_location_items) {
            materialItems.push({
              ...materialItem,
              total_quantity: recommendItem.remain_quantity,
              recommend_location: recommendItem.location_number ?? this.$t("库存不足"),
            });
          }
        } else {
          materialItems.push({
            ...materialItem,
            recommend_location: this.$t("库存不足"),
          });
        }
      }

      return materialItems;
    },
    totalWeight() {
      let totalWeight = 0;
      for (let item of this.dataItems) {
        totalWeight = NP.plus(totalWeight, NP.times(item.total_quantity, item.material_weight || 0));
      }
      return NP.times(totalWeight, 0.001);
    },
    totalVolume() {
      let totalVolume = 0;
      for (let item of this.dataItems) {
        totalVolume = NP.plus(totalVolume, NP.times(item.total_quantity, item.material_volume || 0));
      }
      return totalVolume;
    },
  },
  methods: {
    initData() {
      this.getData(this.$route.query.id);
    },
    getData(id) {
      this.spinning = true;
      this.materialLoading = true;
      document.getElementById("jsbarcode").html = "";
      pickingDetail({ id })
        .then((data) => {
          this.detailInfo = data;
          var jsbarcode = new JsBarcode(this.$refs.jsbarcode, data.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
        })
        .finally(() => {
          this.spinning = false;
        });
      pickingMaterials({ id })
        .then((data) => {
          this.materialItems = data;
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style>
.jsbarcode {
  float: right;
}
</style>
